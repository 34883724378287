<template>
  <div>
    <CContainer fluid>
      <h1>
        My Documents
      </h1>
      <CRow>
        <CCol xl="3" md="6" class="p-3">
          <BorderlessCard
              :src="'star.png'"
              title="My Documents"
              navlink=""
          >
            <template v-slot:description>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi minus sint voluptatem. Animi
              at, earum eligendi, error, fuga iste minus neque numquam placeat quasi quod velit. Alias aut
              earum eius hic non numquam, vero. Animi asperiores aut beatae consequuntur cum dignissimos
              dolores eaque facilis illo impedit inventore ipsa magnam modi molestiae non odit optio,
              pariatur perspiciatis quis ratione sequi similique vel vero! A ad, aliquam aperiam assumenda
              at aut delectus dolore dolorem ea eius eos eveniet expedita facilis fuga illo illum ipsa
              libero magnam magni minima mollitia nam natus nihil odit omnis, pariatur praesentium quis
              quisquam ratione repellendus rerum sapiente sequi sit tempora, temporibus ullam voluptatum.
              Debitis dignissimos doloribus eveniet expedita
            </template>

          </BorderlessCard>
        </CCol>
        <CCol xl="3" md="6" class="p-3">
          <CustomCard
              :src="'star.png'"
              title="Agreements & Conditions"
              description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem beatae error fuga
                            perferendis, provident quibusdam repellat? Ad dolorum ducimus enim, nobis nostrum
                            repudiandae sunt unde vero? Accusamus labore maiores nulla."
              :btn-text="'Manage'"
              navlink=""
          >
          </CustomCard>
        </CCol>
        <CCol xl="3" md="6" class="p-3">
          <CustomCard
              :src="'star.png'"
              title="Saved Documents"
              description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem beatae error fuga
                            perferendis, provident quibusdam repellat? Ad dolorum ducimus enim, nobis nostrum
                            repudiandae sunt unde vero? Accusamus labore maiores nulla."
              :btn-text="'Manage'"
              navlink="mydocuments/saved"
          >
          </CustomCard>
        </CCol>
        <CCol xl="3" md="6" class="p-3" @click="$emit('newDoc',true)">
          <GenericGridAddNewCard emit="newDoc" description="some description" title="Add new document" height="40">
          </GenericGridAddNewCard>
        </CCol>
      </CRow>
    </CContainer>

  </div>
</template>

<script>

import BorderlessCard from "../viewcomponents/BorderlessCard";
import CustomCard from "../viewcomponents/CustomCard";
import Dropzone from "@shared_vue/components/dropzone/vuedropzone2/Dropzone"
import vueDropzone from "@shared_vue/components/dropzone/vuedz"
import GenericGridAddNewCard from "@/views/dashcards/GenericGridAddNewCard.vue";

export default {
  name: "MyDocumentsMain",
  components: {BorderlessCard, CustomCard, Dropzone, vueDropzone, GenericGridAddNewCard},
  methods: {
    myParamName() {
      return "files";
    }
  },
  data: function () {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_MYVAULT_API_URL + '/file/UploadDirect',
        paramName: this.myParamName,
        uploadMultiple: true,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.generic-grid-card {
  min-height: 200px;
}

.btn-card-bg {
  border-radius: 20px;
  border: 2px dashed #8080807d !important;
  box-shadow: none !important;
  padding: 0;
  background: white;
  color: gray;

  &:hover {
    background: #636f83;
    color: white;
  }

  span {
    font-size: 7rem;
    font-weight: 900;
  }
}
</style>
