import axios from 'axios'

export default {
  getSignedURL (file) {
    let endpoint = process.env.VUE_APP_MYVAULT_API_URL+'file/SignForUpload/'
    let payload = {
      filePath: file.name,
      contentType: file.type
    }
    return axios.post(endpoint, payload)
      .then((res) => {
        return Promise.resolve(res.data || '/')
      })
      .catch((err) => {
        console.error(err)
        return Promise.reject('/')
      })
  }
}
